import dynamic from 'next/dynamic'

export const VideoSection = dynamic(() => import('./video-section'), {
    ssr: false,
})

export const VideoVisual = dynamic(() => import('./video-visual'), {
    ssr: false,
})

export const Video = dynamic(() => import('./video'), { ssr: false })
