import type { FunctionComponent } from 'react'
import { useEffect } from 'react'

import { uiStore } from '../store/ui/ui'
import { ThemeMode } from '../utils/theme'

/**
 * Sets the mode of the page to the given value.
 * @param targetMode The mode to set the page to (dark, light, or system).
 * @param enabled Whether the hook should be enabled.
 */
export const useMode = (targetMode = ThemeMode.Default) => {
    useEffect(() => uiStore.setMode(targetMode), [targetMode])
}

export const ChangeMode: FunctionComponent<
    React.PropsWithChildren<{ mode: ThemeMode }>
> = ({ mode }) => {
    useMode(mode)
    return null
}
